@import 'src/components/layout/responsive.mixin';

:global(.theme-unite) .modal,
:global(.theme-unite).modal {
  border-radius: 32px;

  .close-button {
    right: 12px;
    top: 12px;
    color: black;
  }
}

:global(.type-slide) .modal,
:global(.type-slide).modal {
  @include less-than-lg {
    min-width: 100%;
    min-height: 90%;
    max-height: 90%;
    bottom: 0;
    left: 0;
    transform: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  max-width: 50%;
  max-height: 80%;
  top: unset;
  left: 50%;
  bottom: 50%;
  display: flex;
  transform: translate(-50%, 50%);
  translate: 0px 100vh;
  transition: translate 0.5s ease-out;

  &.show {
    @include less-than-lg {
      bottom: 0;
      left: 0;
      transform: unset;
    }

    transform: translate(-50%, 50%);
    translate: 0px 0px;
  }
}

.modal {
  z-index: 9001;
  position: fixed;
  background: white;
  margin: auto;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform var(--anim-speed-normal);
  max-width: 100%;
  max-height: 100%;
  @media screen and (max-width: 641px) {
    min-width: 100%;
    min-height: 100%;
  }

  overflow: auto;
  display: none;
  flex-direction: column;

  &.show {
    transform: translate(-50%, -50%) scale(1);
    display: flex;
  }

  .top-bar {
    position: sticky;
    top: 0;
    z-index: 9001;
  }

  .close-button {
    position: absolute;
    right: 6px;
    top: 6px;
    background: var(--color-white-alpha-900);
  }
}
.disable-animation {
  transition: none !important;
}
