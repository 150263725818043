@import '../../variables';

:global(.theme-dark) .accordion,
:global(.theme-dark).accordion {
  .product-tabs {
    margin: 0;
  }

  .collapsible {
    &--button {
      color: var(--color-white-alpha-500);
      text-transform: unset;
    }

    &-content {
      color: var(--color-white-alpha-500);
    }
  }
}

.accordion {
  margin: 0 auto;
  transition: height 0.35s ease-in-out;

  .product-tabs {
    margin: 80px 0;
  }

  .product-tabs .collapsible {
    padding: 0 24px;
  }

  .tabBorders {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }

  .collapsible {
    overflow: hidden;

    &+.collapsible {
      border-top: none;
    }

    &--button {
      font: inherit;
      margin: 0;
      overflow: visible;
      font-weight: 500;
      color: black;
      transition: color 0.2s ease-in-out;
      letter-spacing: 0.12em;
      margin-bottom: 5px;
      font-size: var(--whoop-font-size-xs);
      font-family: var(--whoop-font-family-bold);
      text-transform: uppercase;
      display: block;
      position: relative;
      width: 100%;
      text-align: left;
      cursor: pointer;
      padding: 20px 0;

      &[aria-expanded='true'] .collapsible-plus::before,
      &[aria-expanded='true'] .collapsible-plus::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      &[aria-expanded='true'] .collapsible-plus::before {
        opacity: 0;
      }

      &[aria-expanded='true'] .collapsible--inner {
        visibility: visible;
        transition: height 0.35s ease-in-out;
      }
    }

    &--inner {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height var(--anim-speed-normal) var(--anim-interp-default);

      &.expanded {
        max-height: 1000px;
      }
    }

    &-content {
      word-break: break-word;
      font-family: var(--whoop-font-family-light);
      padding-bottom: 18px;
      font-weight: 400;
      font-style: normal;
      color: black;
      font-size: 14px;
    }
  }

  .left-plus {
    left: 0;
  }

  .right-plus {
    right: 0;
  }

  .right-content {
    padding-left: 24px;
  }

  .product-tabs .collapsible-content {
    img {
      max-width: 100%;
      object-fit: contain;
    }

    @media screen and (min-width: $screen-m-min) {
      padding-right: 80px;
      padding-bottom: 45px;
    }
  }
}


// This could be it's own micro-component
.collapsible-plus {
  position: absolute;
  top: calc(50% - (11px / 2));
  width: 11px;
  height: 11px;

  // vertical
  &:before {
    width: 11px;
    height: 1px;
    opacity: 1;
  }
  // horizontal
  &:after {
    width: 1px;
    height: 11px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    background-color: currentColor;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  }

  &[aria-expanded='true'] {
    // vertical
    &:before {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(90deg);
    }
    // horizontal
    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
