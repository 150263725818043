@import 'src/styles/typography.mixin.scss';

:global(.theme-unite) .container,
:global(.theme-unite).container {
  padding: 8px;
  gap: 12px;
  justify-content: center;
  .icon-small {
    color: var(--color-branding-red);
    margin: 0;
    padding: 0;
  }
  .text {
    @include p5;
    color: var(--color-branding-red);
    flex: unset;
    width: auto;
    text-align: center;
  }
}

.frame {
  border-radius: 6px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-xs-4) var(--spacing-xs-4) 0;

  & > *:first-child {
    margin-right: var(--spacing-xs-3);
  }

  img {
    max-width: 32px;
    max-height: 32px;
  }
}

.bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: calc(var(--spacing-xs-4) / 2) var(--spacing-xs-4);
}

.clickable {
  cursor: pointer;
}

.alert-header{
  background-color: var(--color-sleep-blue);
  color: var(--whoop-color-white);
  padding: var(--spacing-xs-2);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.text {
  font-size: 12px;
  font-family: var(--whoop-font-family-normal);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  flex: 1;
}

.icon-small {
  width: 14px;
  height: 14px;
  padding: var(--spacing-xs-2);
  color: var(--color-white);
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    width: 20px;
  }
}

.icon-info {
  background-color: var(--color-strain-blue);
}
.icon-error {
  background-color: var(--color-branding-red);
}
.icon-warning {
  background-color: var(--color-yellow);
}
.icon-success {
  background-color: var(--color-green);
}
.icon-neutral {
  background-color: var(--color-gray-400);
  color: var(--color-black);
}
.icon-pro {
  background-color: var(--color-pro-gold-alpha-300);
  width: 24px;
  height: 24px;
  padding: 4px;
  min-width: 24px; // prevents icon from shrinking
  box-sizing: content-box; // prevents icon from shrinking
  color: var(--color-pro-gold);
}

.action-right {
  margin-left: 8px;
}

.action-bottom {
  margin-left: 0;
  margin-bottom: calc(var(--spacing-xs-4) / 2);
  width: 100%;
}

:global(.theme-dark) .text,
:global(.theme-dark).text,
:global(.theme-dark) {
  color: var(--color-white);
}

.alert-info {
  background-color: var(--color-strain-blue-alpha-200);
}
.alert-error {
  background-color: var(--color-red-alpha-100);
}
.alert-unite-error {
  background-color: var(--color-light-pink);
}
.alert-warning {
  background-color: var(--color-yellow-alpha-200);
}
.alert-success {
  background-color: var(--color-green-alpha-200);
}
.alert-neutral {
  background-color: var(--color-gray-200);
}
.alert-pro {
  background-color: var(--color-pro-gold-alpha-200);

  .action {
    --color-primary: var(--color-pro-gold);
    --color-primary-contrast: var(--color-black);
  }
}

:global(.theme-dark) .alert-info,
:global(.theme-dark).alert-info {
  background-color: var(--color-strain-blue-alpha-300);
}

:global(.theme-dark) .alert-error,
:global(.theme-dark).alert-error {
  background-color: var(--color-red-alpha-300);
}

:global(.theme-dark) .alert-warning,
:global(.theme-dark).alert-warning {
  background-color: var(--color-yellow-alpha-300);
}

:global(.theme-dark) .alert-success,
:global(.theme-dark).alert-success {
  background-color: var(--color-green-alpha-300);
}
