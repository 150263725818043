.subtotal {
  font-family: var(--whoop-font-family-normal);
  font-size: 14px;
  width: 100%;
  margin: 0 auto;

  .line {
    display: flex;
    align-items: center;
    min-height: 1.4em;

    .label {
      flex: 1;
      color: var(--color-gray-700);
    }

    .at-checkout {
      font-size: 0.8em;
      color: var(--color-gray-700);
    }

    .price {
      text-align: right;
      margin: 0;
    }
  }

  .bold {
    font-weight: bold;
  }

  .free-text {
    color: var(--color-branding-red);
    font-weight: bold;
  }

  .muted {
    color: var(--color-gray-700);
  }
}
